<template>

	<!--Banner section start-->
	<div class="cy_bread_wrapper">
		<div class="container">
			<div class="row">
				<div class="col-lg-12 col-md-12">
					<h1 style="text-shadow: 1px 1px 4px #000;">{{ product.ProductName }}</h1>
				</div>
			</div>
		</div>
	</div>

    <!-- shop section start -->
    <div class="cy_shop_wrapper">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="cy_shop_single_page">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">

                                <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
                                    <ol class="carousel-indicators">
                                        <li v-for="(img,index) in product.images" :key="'indicator'+index" data-target="#carouselExampleIndicators" :data-slide-to="index" :class="index == 0 ? 'active' : ''"></li>
                                    </ol>
                                    <div class="carousel-inner">
                                        <div v-for="(img,index) in product.images" :class="index == 0 ? 'carousel-item active' : 'carousel-item'" :key="'img'+index">
                                            <v-lazy-image class="d-block w-100" :src="img" alt="" />
                                        </div>
                                    </div>
                                    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                                        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Previous</span>
                                    </a>
                                    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                                        <span class="carousel-control-next-icon" aria-hidden="true"></span>
                                        <span class="sr-only">Next</span>
                                    </a>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="summary entry-summary pt-2">
                                    <h1 class="product_title">{{ product.ProductName }}</h1>
                                    <h4 class="product-price-amount">{{product.ProductCurrency}}{{product.Custprice}}</h4>
                                    <p class="woocommerce-product-detail" v-html="product.SalesText"></p>
                                    <div class="quantity" v-if="!currentUser">
                                        <button type="button" data-toggle="modal" data-target="#exampleModal" class="cy_button">
                                            <i class="fa fa-check-circle"></i> Register your interest
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-md-12 pt-5">
                                <div class="woocommerce-tabs">
                                    <ul class="tabs">
                                        <li class="nav-item">
                                            <a class="nav-link active" href="#discription"  data-toggle="tab">{{ reslang['idDescription'] }}</a>
                                        </li>
                                    </ul>
                                    <div class="tab-content">
                                        <div role="tabpanel" class="tab-pane fade show active" id="discription">
                                            <h3>{{ reslang['idDescription'] }}</h3>
                                            <p class="woocommerce-product-detail" v-html="product.ProductText"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <router-link to="/register" class="cy_button">{{ reslang['idBackToListPage'] }}</router-link>
                </div>
                
            </div>
        </div>
        <vue-basic-alert 
            :duration="500" 
            :closeIn="3000"
        ref="alert" />

        <!-- Modal -->
        <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                <div class="modal-body">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="cy_sign_img">
                                <img src="/img/signup.jpg" alt="popup" class="img-fluid">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-6">
                            <div class="cy_sign_form text-left">
                                <form @submit.prevent="register()">
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>

                                    <h1>Register</h1>

                                    <p><i>* is required</i></p>
                                    <br/>

                                    <label>*Email</label>
                                    <input type="email" v-model="form.email" placeholder="Email..." class="form-control" required>

                                    <label>*Password</label>
                                    <input type="password" v-model="form.password" placeholder="Password..." class="form-control" required>

                                    <label>*First Name</label>
                                    <input type="text" v-model="form.first_name" placeholder="First Name..." class="form-control" required>

                                    <label>*Last Name</label>
                                    <input type="text" v-model="form.last_name" placeholder="Last Name..." class="form-control" required>

                                    <label>*Gender</label>
                                    <select class="form-control" v-model="form.gender">
                                        <option value="M">Male</option>
                                        <option value="F">Female</option>
                                    </select>
                                    
                                    <label>Your Strava ID, EX : <i>https://www.strava.com/athletes/35106340</i></label>
                                    <input type="text" v-model="form.strava_id" placeholder="Strava ID..." class="form-control">

                                    <label>*Birthday Year</label>
                                    <input type="number" v-model="form.birth_date" min="1900" :max="new Date().getFullYear()" step="1" class="form-control" required>

                                    <center v-show="spinner == false"><button type="submit" class="cy_button">Register your interest</button></center>
                                    <center v-show="spinner == true"><div class="spinner"></div></center>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>

        <!-- <div class="modal show" id="signup" style="display: block;">
            <div class="cy_signup">
                
            </div>
        </div> -->
    </div>
</template>

<script>
// import subContent from './subContentNew.vue';

export default {
	name: 'product-content',
	// components: { subContent},
	data() {
		return {
			language_strings : ['idSize', 'idColor', 'idQty','idRequestPrice', 'idDescription',
                'idBackToListPage'
            ],
			reslang: [],
			product: {
                images:[],
                ProductName:'',
                ProductCurrency:'',
                Custprice:'',
                TeaserText:'',
                SalesText:'',
                ProductText:''
            },
			base: 'https://www.admin.tourdeice.bike',
			loading: true,
            spinner:false,
            mute:false,
            form:{
                birth_date: new Date().getFullYear(),
                strava_id:'',
                gender: 'M'
            }
		}
	},
	mounted() {
        if (!this.$storage) {
			this.$storage.setStorageSync('langSessionID', "no")
		}
		if(this.$storage.getStorageSync('langSessionID') == undefined){
			this.$storage.setStorageSync('langSessionID', "no")
		}

		this.language_strings['sess'] = this.$storage.getStorageSync('langSessionID')
		var datalangs = this.$store.dispatch('languages', this.language_strings)

		datalangs.then(response => {
			this.reslang = response
		})
		.catch((error) => {
			console.log(error, 'Got nothing for this user except bad news.')
		})

		this.getdata();
	},
	methods: {
		async getdata() {
			
			window.scrollTo(0, 0)

            this.mute = true

            let lang_id = this.$storage.getStorageSync('langSessionID')

			this.axios.get("get-product/"+this.$route.params.id+"?lang_id="+lang_id)
            .then((response) => {
                this.product = response.data.data
                this.mute = false
            })
            .catch((error) => {
                console.log(error)
                this.mute = false
                this.$router.push({ name: 'NotFound' })
            })
		},

        register(){
            this.spinner = true
            this.form.birth_date = this.form.birth_date+'-01-01'
			this.axios.post("new/register",this.form)
            .then((response) => {
                this.spinner = false

                let type_alert = 'success'
                let text_alert = 'Success'
                if(response.data.error == 1){
                    type_alert = 'error'
                    text_alert = 'Error'
                }

                this.$refs.alert.showAlert(
                    type_alert,
                    response.data.msg,
                    text_alert
                )

                this.form = {
                    birth_date: new Date().getFullYear(),
                    strava_id:'',
                    gender: 'M'
                }
                
                document.getElementById('exampleModal').click()
            })
            .catch((error) => {
                console.log(error)
                this.$refs.alert.showAlert(
                    'error',
                    'Something is wrong',
                    'Error'
                )
                this.spinner = false
            })
        }
	},
    watch: {
		mute(val) {
			document.getElementById('mute').style.display = val ? "block" : "none"
		}
	},
	computed: {
		currentUser() {
			return this.$store.getters.currentUser;
		},
	},
}
</script>